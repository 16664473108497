import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ProductRegister.module.css";
import { SupplierData } from "../Suppliers/SupplierTable";
import { useEffect, useState } from "react";

export type ProductRegisterData = {
    productname: string;
    manufacture: string;
    supplier: string;
    moldingsite: string;
    cost: number;
    leftright: string;
    size: string;
    color: string;
}

export default function ProductRegister() {
    const navigate = useNavigate();

    const [suppliers, setSuppliers] = useState<SupplierData[]>();
    const [manufactures, setManufactures] = useState<string[]>([]);
    const [moldingsite, setMoldingsite] = useState<string[]>([]);

    useEffect(() => {
        const fetchSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier")
            const data = await response.json();
            setSuppliers(data);
        }

        const fetchManufacture = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufacture = data.manufacture.map((item: Object) => Object.values(item));
            setManufactures(Manufacture);
        }

        const fetchmoldingsite = async () => {
            const response = await fetch("https://api.poweb.jp/moldingsite");
            const data = await response.json();
            setMoldingsite(data);
        }

        fetchSupplier();
        fetchManufacture();
        fetchmoldingsite();
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ProductRegisterData>(
        { mode: 'onBlur' }
    )

    const onSubmit: SubmitHandler<ProductRegisterData> = async (data) => {
        const datastr = JSON.stringify(data);

        const response = await fetch("https://api.poweb.jp/createProduct", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: datastr
        })
        const resjson = await response.json();
        alert(resjson.message);
        if (resjson.submit) {
            navigate("/Products");
        }
    }

    return (

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h2 className={styles.h2}>製品の登録</h2>
            <p className={styles.p}>すべての項目を入力してください。</p>
            <p className={styles.p}>製品の名称を入力してください。<br />
                （全角で入力してください。）</p>
            <input type="text"
                {...register("productname",
                    {
                        required: "製品の名称は必須です。",
                        maxLength: {
                            value: 50, message: "50文字以内で入力してください。"
                        },
                        pattern: { value: /^[Ａ-Ｚ\u4E00-\u9FFFぁ-んァ-ヶ０-９ー・　]+$/, message: "全角で入力してください。" },
                    })} className={styles.input} />
            <span>{errors.productname?.message}</span>

            <p className={styles.p}>製品のメーカー名を選択してください。</p>
            <select {...register("manufacture", { required: "メーカーの名称は必須です。" })} className={styles.select}>
                <option value="">--選択--</option>
                {manufactures ? manufactures.map((item, index) => <option key={index} value={item}>{item}</option>) : null}
            </select>
            <span>{errors.manufacture?.message}</span>

            <p className={styles.p}>製品の発注先を選択してください。</p>
            <select {...register("supplier", { required: "必ず選択してください。" })} className={styles.select}>
                <option value="">--選択--</option>
                {suppliers ? suppliers.map((item, index) => (<option key={index} value={item.supplier}>{item.supplier}</option>)) : null}
            </select>
            <span>{errors.supplier?.message}</span>

            <p className={styles.p}>製品の採型区分を選択してください。</p>
            <select {...register("moldingsite", { required: "必ず選択してください。" })} className={styles.select}>
                <option value="">--選択--</option>
                {moldingsite ? moldingsite.map((item, index) => (<option key={index} value={item}>{item}</option>)) : null}
            </select>
            <span>{errors.moldingsite?.message}</span>

            <p className={styles.p}>仕入れ値を入力してください。<br />
                （半角数字で入力してください。）</p>
            <input type="number" {...register("cost", {
                required: "仕入れ値は必須です。"
            })} className={styles.input} />
            <span>{errors.cost?.message}</span>
            <p>左右の有無を選択してください。</p>
            <select {...register("leftright")} className={styles.select}>
                <option value='[""]'>左右無し</option>
                <option value='["左","右"]'>左右有り</option>
            </select>
            <p className={styles.p}>サイズを入力してください。サイズ区分がない場合は未入力で進んでください。<br />
                （半角の　,　で区切ってすべてのサイズを入力してください。<br />
                カナは全角、アルファベットと数字は半角で入力してください。）<br />
                例）S,M,L,LL   S,M-L,ショートS,ショートM-L　等</p>

            <input type="text"
                {...register("size",
                    {
                        maxLength: {
                            value: 50, message: "50字以内で表現してください。"
                        },
                        pattern: { value: /^[\u4E00-\u9FFFA-Zァ-ヶ0-9\-ー,]+$/, message: "アルファベットの大文字、全角カナ、半角数字で入力してください。" },
                    })} className={styles.input} />
            <span>{errors.size?.message}</span>
            <p className={styles.p}>色を入力してください。色区分がない場合は未入力で進んでください。<br />
                （半角の　,　で区切ってすべてのサイズを入力してください。<br />
                全角カナか漢字で入力してください。）<br />
                例）ベージュ、ブラック  等</p>

            <input type="text"
                {...register("color",
                    {
                        maxLength: {
                            value: 20, message: "20字以内で表現してください。"
                        },
                        pattern: {
                            value: /^[\u4E00-\u9FFFァ-ヶー,]+$/, message: "漢字か全角カタカナで入力してください。"
                        },
                    })} className={styles.input} />
            <span>{errors.color?.message}</span>

            <Button type="submit" variant="contained" style={{ margin: 15 }}>登録</Button>
            <Link to="/Products"><Button>キャンセル</Button></Link>
        </form>
    )
}
