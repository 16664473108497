import React, { useState, useEffect, useContext } from "react"
import styles from "./Calendar.module.css"
import { Button } from "@mui/material";
import { ReservationType } from "../page/Reservation/Reservation";
import { AuthContext, AuthContextType } from "../Auth"
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export type PushReservation = {
    productname: string;
    color: string;
    left_right: string;
    size: string;
    quantity: number;
    reservation_date: string;
    username: string;
}

export default function Calendar() {

    const [schedule, setSchedule] = useState<ReservationType[]>([]);
    const [selectedDate, setSelectedDate] = useState<DateObj | null>(null);
    const [selectManufacture, setSelectManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>("");
    const [selectProduct, setSelectProduct] = useState<string[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string>("");
    const [selectLR, setSelectLR] = useState<string[]>([]);
    const [selectSize, setSelectSize] = useState<string[]>([]);
    const [selectColor, setSelectColor] = useState<string[]>([]);
    const [selectedLR, setSelectedLR] = useState<string>("");
    const [selectedSize, setSelectedSize] = useState<string>("");
    const [selectedColor, setSelectedColor] = useState<string>("");
    const [inputedQuantity, setInputedQuantity] = useState<number>(1);
    const { auth } = useContext(AuthContext) as AuthContextType;
    const [reload, setReload] = useState<boolean>(false);

    const day: string[] = [
        "(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"
    ]

    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures = data.manufacture.map((item: Object) => Object.values(item))
            setSelectManufacture(Manufactures);
        }
        fetchSelectInit();
    }, [])

    useEffect(() => {
        const fecthSchedule = async () => {
            const response = await fetch("https://api.poweb.jp/getreservation")
            const data = await response.json();
            let newData = [...data];
            for (let i = 0; i < data.length; i++) {
                let reservationDate = new Date(data[i].reservation_date);
                reservationDate.setHours(reservationDate.getHours() + 9);
                if (newData[i].prepared === 0) { newData[i].prepared = false };
                if (newData[i].prepared === 1) { newData[i].prepared = true };
                newData[i].reservation_date = {
                    year: reservationDate.getFullYear(),
                    month: reservationDate.getMonth() + 1,
                    date: reservationDate.getDate()
                }
            }
            setSchedule(newData);
        }
        fecthSchedule();
    }, [reload])

    useEffect(() => {
        const fetchProduct = async () => {
            const response = await fetch("https://api.poweb.jp/product", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "manufacture": selectedManufacture
                })
            });
            const data = await response.json();
            setSelectProduct(data);
        }
        fetchProduct();
    }, [selectedManufacture])

    useEffect(() => {
        const fetchProductdata = async () => {
            if (selectedProduct === "") {
                setSelectLR([]);
                setSelectSize([]);
                setSelectColor([]);
                return
            }
            const response = await fetch("https://api.poweb.jp/productdata", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "productname": selectedProduct })
            })
            const data = await response.json();
            setSelectLR(data.leftright);
            setSelectSize(data.size);
            setSelectColor(data.color);
            if (data.leftright.length > 0) {
                setSelectedLR(data.leftright[0]);
            }
            if (data.size.length > 0) {
                setSelectedSize(data.size[0]);
            }
            if (data.color.length > 0) {
                setSelectedColor(data.color[0]);
            }
        }
        fetchProductdata();

    }, [selectedProduct])

    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();
    const dayOfWeek = today.getDay();

    type DateObj = {
        date: number;
        month: number;
        year: number;
    }
    const days: DateObj[] = [];

    for (let i = 0; i < 5 * 7; i++) {
        const day: DateObj = {
            date: new Date(year, month, date - dayOfWeek + i).getDate(),
            month: new Date(year, month, date - dayOfWeek + i).getMonth(),
            year: new Date(year, month, date - dayOfWeek + i).getFullYear(),
        }
        days.push(day);
    }
    const dayMatrix: DateObj[][] = [];
    for (let i = 0; i < 5; i++) {
        const week = days.splice(0, 7);
        dayMatrix.push(week);
    }

    const handleDate = (date: DateObj) => {
        if (new Date(date.year, date.month, date.date) < new Date() || (new Date(date.year, date.month, date.date).getDay() === 0) || (new Date(date.year, date.month, date.date).getDay() === 6)) {
            return;
        }
        setSelectedDate(date);
    }

    const handleBack = () => {
        setSelectedDate(null);
        setSelectedManufacture("");
        setSelectedProduct("");
    }

    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value);
    }

    const handleLR = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLR(e.target.value);
    }

    const handleSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSize(e.target.value);
    }
    const handleColor = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedColor(e.target.value);
    }

    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputedQuantity(Number(e.target.value));
    }

    const pushReservation = async () => {
        if (!selectedDate || !auth.username) {
            return
        }
        if (!selectedProduct) {
            return alert("製品を選択してください。");
        }
        if (inputedQuantity < 1) {
            return alert("数量が0以下になっています。");
        }
        const newReservation: PushReservation = {
            username: auth.username,
            productname: selectedProduct,
            color: selectedColor,
            left_right: selectedLR,
            size: selectedSize,
            quantity: inputedQuantity,
            reservation_date: `${selectedDate.year}-${selectedDate.month + 1}-${selectedDate.date}`,
        }
        const response = await fetch("https://api.poweb.jp/pushreservation", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newReservation)
        });
        const data = await response.json();
        if (data.result) {
            setSelectedDate(null);
            setSelectedProduct("");
            setSelectedManufacture("");
            setInputedQuantity(1);
            setReload(!reload);
        }
    }

    const findReservation = (date: DateObj, ReservationData: ReservationType[]) => {

        const reservationItems = ReservationData.filter(item =>
            item.reservation_date.year === date.year &&
            item.reservation_date.month === (date.month + 1) &&
            item.reservation_date.date === date.date)
        if (reservationItems === undefined) {
            return null;
        } else {
            return reservationItems;
        };
    }

    const handlePrepared = async (e: React.MouseEvent<HTMLButtonElement>, reservation_id: number) => {
        try {
            e.stopPropagation();
            const response = await fetch("https://api.poweb.jp/prepared", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ reservation_id: reservation_id })
            })
            const data = await response.json();
            if (data.result) {
                setReload(!reload);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleNotPrepared = async (e: React.MouseEvent<HTMLButtonElement>, reservation_id: number) => {
        try {
            e.stopPropagation();
            const response = await fetch("https://api.poweb.jp/notprepared", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ reservation_id: reservation_id })
            })
            const data = await response.json();
            if (data.result) {
                setReload(!reload);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>, reservation_id: number) => {
        try {
            e.stopPropagation();
            const response = await fetch("https://api.poweb.jp/deletereservation", {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ reservation_id: reservation_id })
            })
            const data = await response.json();
            if (data.result) {
                setReload(!reload);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={styles.div}>
            {selectedDate ? <div className={styles.back} onClick={() => handleBack()}></div> : null}
            <div className={styles.calendar}>{dayMatrix.map((item, index) =>
                <div key={index} className={styles.dayMatrix}>{item.map((item2, index2) =>
                    <div key={index2} onClick={() => handleDate(item2)} className={(index2 < dayOfWeek && index === 0) ? styles.below : (index2 === dayOfWeek && index === 0) ? styles.today : (index2 === 0) ? styles.td1 : (index2 === 6) ? styles.td2 : styles.td}>
                        <div>{(item2.date === 1 && item2.month === 0) ? `${item2.year}/${item2.month + 1}/${item2.date}${day[new Date(item2.year, item2.month, item2.date).getDay()]}` : (item2.date === 1) ? `${item2.month + 1}/${item2.date}${day[new Date(item2.year, item2.month, item2.date).getDay()]}` : `${item2.date}${day[new Date(item2.year, item2.month, item2.date).getDay()]}`}
                        </div>
                        {schedule ?
                            findReservation(item2, schedule)?.map((item3, index3) => <div className={styles.card} key={index3}>{item3.username + "   " + item3.productname + "   " + item3.left_right + "   " + item3.size + "   " + item3.color + "   ×" + item3.quantity + "  "}{item3.prepared ? <span className={styles.mark} onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleNotPrepared(e, item3.reservation_id)}><CheckIcon sx={{ color: "blue" }} /></span> : <span className={styles.mark} onClick={(e: React.MouseEvent<HTMLButtonElement>) => handlePrepared(e, item3.reservation_id)}> <PriorityHighIcon sx={{ color: "red" }} /></span>}<Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDelete(e, item3.reservation_id)} sx={{ size: "small", marginLeft: 10 }}>削除</Button></div>) : null}
                        {selectedDate ? (selectedDate.date === item2.date && selectedDate.month === item2.month) &&
                            <div className={styles.window}>
                                <h4 className={styles.h4}>{selectedDate.year}/{selectedDate.month + 1}/{selectedDate.date}の出庫予約</h4>
                                <label htmlFor="メーカー" className={styles.label}>メーカー
                                    <select value={selectedManufacture} id="メーカー" onChange={handleManufacture} className={styles.select}>
                                        <option value="">未設定</option>
                                        {selectManufacture.map(item => (<option key={item} value={item}>{item}</option>))}
                                    </select>
                                </label>
                                <label htmlFor="製品" className={styles.label}>製品
                                    <select value={selectedProduct} id="製品" onChange={handleProduct} className={styles.select}>
                                        <option value="">未設定</option>
                                        {selectProduct.map(item => (<option key={item} value={item}>{item}</option>))}
                                    </select>
                                </label>
                                <label htmlFor="左右" className={styles.label}>左右
                                    <select value={selectedLR} id="左右" onChange={handleLR} className={styles.select}>
                                        {selectLR.map(item => (<option key={item} value={item}>{item}</option>))}
                                    </select>
                                </label>
                                <label htmlFor="サイズ" className={styles.label}>サイズ
                                    <select value={selectedSize} id="サイズ" onChange={handleSize} className={styles.select}>
                                        {selectSize.map(item => (<option key={item} value={item}>{item}</option>))}
                                    </select>
                                </label>
                                <label htmlFor="色" className={styles.label}>色
                                    <select value={selectedColor} id="色" onChange={handleColor} className={styles.select}>
                                        {selectColor.map(item => (<option key={item} value={item}>{item}</option>))}
                                    </select>
                                </label>
                                <label htmlFor="数量" className={styles.label}>数量
                                    <input id="数量" type="number" onChange={handleQuantity} className={styles.input} defaultValue={1} />
                                </label>
                                <Button variant="contained" sx={{ width: 10, margin: 1, alignSelf: "center" }} onClick={() => pushReservation()}>登録</Button>
                            </div> : null}</div>)}</div>)
            }</div>
        </div >
    )
}