import { useState, useEffect, Fragment } from "react"
import styles from "./PriceList.module.css"
import ExcelDownloadButton from "../../component/ExcelDownloadButton";

type price_manufacture = {
    productname: string;
    cost: number;
    moldingsite: string;
    price: number;
}

type data_manufacture = {
    manufacture: string;
    data: price_manufacture[];
}

type price_moldingsite = {
    manufacture: string;
    productname: string;
    cost: number;
    price: number;
}
type data_moldingsite = {
    moldingsite: string;
    data: price_moldingsite[];
}

const moldingsite_part = [
    { moldingsite: "A-1", part: "股装具" },
    { moldingsite: "A-2", part: "骨盤帯長下肢装具" },
    { moldingsite: "A-3", part: "長下肢装具" },
    { moldingsite: "A-4", part: "膝装具" },
    { moldingsite: "A-5", part: "短下肢装具" },
    { moldingsite: "A-6", part: "短下肢装具" },
    { moldingsite: "A-7", part: "足装具" },
    { moldingsite: "A-8", part: "股関節外転装具" },
    { moldingsite: "A-9", part: "股関節外転装具" },
    { moldingsite: "A-10", part: "骨盤帯長下肢装具（両側）" },
    { moldingsite: "B-1", part: "長靴、半長靴、チャッカ靴" },
    { moldingsite: "B-2", part: "短靴" },
    { moldingsite: "C-1", part: "頚椎装具（胸腰仙椎装具付）" },
    { moldingsite: "C-2", part: "頚胸椎装具" },
    { moldingsite: "C-3", part: "頚椎装具（カラー）" },
    { moldingsite: "C-4", part: "胸腰仙椎装具（肩ベルト付）" },
    { moldingsite: "C-5", part: "胸腰仙椎装具" },
    { moldingsite: "C-6", part: "腰仙椎装具、仙腸装具" },
    { moldingsite: "D-1", part: "肩装具" },
    { moldingsite: "D-2", part: "肘装具（手部付）、BFO" },
    { moldingsite: "D-3", part: "肘装具" },
    { moldingsite: "D-4", part: "手関節装具" },
    { moldingsite: "D-5", part: "肩装具" },
    { moldingsite: "D-6", part: "指装具" }
]

export default function PriceList() {

    const [manufacture, setManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>();
    const [selectedMoldingsite, setSelectedMoldingsite] = useState<string>();
    const [orderByItem, setOrderByItem] = useState<string>("manufacture");
    const [manufacturepriceData, setManufacturePriceData] = useState<data_manufacture[]>();
    const [moldingsitepriceData, setMoldingistePriceData] = useState<data_moldingsite[]>();

    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures = data.manufacture.map((item: Object) => Object.values(item))
            setManufacture(Manufactures);
        }
        fetchSelectInit();
    }, [])

    useEffect(() => {
        const fetchPriceList = async () => {
            const response = await fetch("https://api.poweb.jp/getpricelist", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    manufacture: selectedManufacture,
                    moldingsite: selectedMoldingsite,
                    group: orderByItem
                })
            })
            const data = await response.json();
            if (orderByItem === "manufacture") {
                setManufacturePriceData(data);
            }
            if (orderByItem === "moldingsite") {
                setMoldingistePriceData(data);
            }
        }
        fetchPriceList();
    }, [selectedManufacture, selectedMoldingsite, orderByItem])

    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleMoldingsite = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMoldingsite(e.target.value);
    }

    const handleGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setOrderByItem(e.target.value);
    }

    const convertWord = (moldingsite: string): string | null => {
        for (let i = 0; moldingsite_part.length > i; i++) {
            if (moldingsite.includes(moldingsite_part[i].moldingsite)) {
                return moldingsite_part[i].part;
            }
        }
        return null;
    }

    const priceA = (price: number, cost: number): number => {
        return (Math.round((price * 0.52 + cost * 1.3) / 10)) * 10
    }

    const priceB = (cost: number): number => {
        return (Math.round(cost * 2 / 10) * 10);
    }

    const basisPrice = (price: number, cost: number): number => {
        const min = Math.min(priceA(price, cost), priceB(cost));
        if (5000 > min || cost <= 1500) { return 5000 }
        return min;
    }

    const excelData = (): string[][] => {
        let data = [];
        if (orderByItem === "manufacture") {
            data.push(["メーカー", "製品名", "採型区分", "A価格", "B価格", "基準額", "改正分", "販売価格"])
        }
        if (orderByItem === "moldingsite") {
            data.push(["装着部位", "製品名", "メーカー", "A価格", "B価格", "基準額", "改正分", "販売価格"])
        }
        if (orderByItem === "manufacture" && manufacturepriceData) {
            manufacturepriceData.map((item: data_manufacture) =>
                item.data.map((item2) =>
                    data.push([item.manufacture, item2.productname, item2.moldingsite, `${priceA(item2.price, item2.cost)}`, `${priceB(item.data[0].cost)}`, `${basisPrice(item2.price, item2.cost)}`, `${Math.floor(basisPrice(item2.price, item2.cost) * 0.06)}`, `${Math.floor(basisPrice(item2.price, item2.cost) * 1.06)}`])));
        }
        if (orderByItem === "moldingsite" && moldingsitepriceData) {
            moldingsitepriceData.map((item: data_moldingsite) =>
                item.data.map((item2) =>
                    data.push([`${convertWord(item.moldingsite) + item.moldingsite}`, item2.productname, item2.manufacture, `${priceA(item.data[0].price, item.data[0].cost)}`, `${priceB(item.data[0].cost)}`, `${basisPrice(item2.price, item2.cost)}`, `${Math.floor(basisPrice(item.data[0].price, item.data[0].cost) * 0.06)}`, `${Math.floor(basisPrice(item2.price, item2.cost) * 1.06)}`]
                    )))
        }
        return data;
    }


    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>価格表</h2>
            <div className={styles.selectwrapper}>
                <label htmlFor="メーカー" className={styles.label}>メーカー
                    <select value={selectedManufacture} id="メーカー" onChange={handleManufacture} className={styles.select}>
                        <option value="">未設定</option>
                        {manufacture.map(item => (<option key={item} value={item}>{item}</option>))}
                    </select>
                </label>
                <label htmlFor="装着部位" className={styles.label}>装着部位
                    <select value={selectedMoldingsite} id="装着部位" onChange={handleMoldingsite} className={styles.select}>
                        <option value="">未設定</option>
                        <option value="A%">下肢装具</option>
                        <option value="D%">上肢装具</option>
                        <option value="C%">体幹装具</option>
                    </select>
                </label>
                <ExcelDownloadButton data={excelData()} />

            </div>
            <div className={styles.selectwrapper}>
                <label htmlFor="OrderByGroup" className={styles.label}>並び替え：
                    <select value={orderByItem} id="OrderByGroup" onChange={handleGroup} className={styles.select}>
                        <option value="manufacture">メーカー</option>
                        <option value="moldingsite">装着部位</option>
                    </select>
                </label>
            </div>
            <div className={styles.scroll}>
                <table className={styles.table}>
                    <thead>
                        {(orderByItem === "manufacture") && < tr className={styles.tr}><th className={styles.th}>メーカー</th><th className={styles.th}>製品名</th><th className={styles.th}>採型区分</th><th className={styles.th}>A価格</th><th className={styles.th}>B価格</th><th className={styles.th}>基準額</th><th className={styles.th}>改正分</th><th className={styles.th}>販売価格</th></tr>}
                        {(orderByItem === "moldingsite") && < tr className={styles.tr}><th className={styles.th}>装着部位</th><th className={styles.th}>製品名</th><th className={styles.th}>メーカー</th><th className={styles.th}>A価格</th><th className={styles.th}>B価格</th><th className={styles.th}>基準額</th><th className={styles.th}>改正分</th><th className={styles.th}>販売価格</th></tr>}
                    </thead><tbody>
                        {(orderByItem === "manufacture" && manufacturepriceData) && manufacturepriceData.map((item: data_manufacture, index: number) =>
                            <Fragment key={index}><tr key={item.manufacture} className={styles.tr}><td rowSpan={item.data.length} className={styles.td1}>{item.manufacture}</td><td className={styles.td}>{item.data[0].productname}</td><td className={styles.td}>{item.data[0].moldingsite}</td><td className={styles.td}>{priceA(item.data[0].price, item.data[0].cost)}</td><td className={styles.td}>{priceB(item.data[0].cost)}</td><td className={styles.td}>{basisPrice(item.data[0].price, item.data[0].cost)}</td><td className={styles.td}>{Math.floor(basisPrice(item.data[0].price, item.data[0].cost) * 0.06)}</td><td className={styles.td}>{Math.floor(basisPrice(item.data[0].price, item.data[0].cost) * 1.06)}</td></tr>
                                {item.data.map((item2, index2) => (index2 > 0) && <tr key={index2} className={styles.tr}><td className={styles.td}>{item2.productname}</td><td className={styles.td}>{item2.moldingsite}</td><td className={styles.td}>{priceA(item2.price, item2.cost)}</td><td className={styles.td}>{priceB(item2.cost)}</td><td className={styles.td}>{basisPrice(item2.price, item2.cost)}</td><td className={styles.td}>{Math.floor(basisPrice(item2.price, item2.cost) * 0.06)}</td><td className={styles.td}>{Math.floor(basisPrice(item2.price, item2.cost) * 1.06)}</td></tr>)}
                            </Fragment>)}
                        {(orderByItem === "moldingsite" && moldingsitepriceData) && moldingsitepriceData.map((item: data_moldingsite, index: number) =>
                            <Fragment key={index}><tr key={item.moldingsite} className={styles.tr}><td rowSpan={item.data.length} className={styles.td1}>{convertWord(item.moldingsite) + `(${item.moldingsite})`}</td><td className={styles.td}>{item.data[0].productname}</td><td className={styles.td}>{item.data[0].manufacture}</td><td className={styles.td}>{priceA(item.data[0].price, item.data[0].cost)}</td><td className={styles.td}>{priceB(item.data[0].cost)}</td><td className={styles.td}>{basisPrice(item.data[0].price, item.data[0].cost)}</td><td className={styles.td}>{Math.floor(basisPrice(item.data[0].price, item.data[0].cost) * 0.06)}</td><td className={styles.td}>{Math.floor(basisPrice(item.data[0].price, item.data[0].cost) * 1.06)}</td></tr>
                                {item.data.map((item2, index2) => (index2 > 0) && <tr key={index2} className={styles.tr}><td className={styles.td}>{item2.productname}</td><td className={styles.td}>{item2.manufacture}</td><td className={styles.td}>{priceA(item2.price, item2.cost)}</td><td className={styles.td}>{priceB(item2.cost)}</td><td className={styles.td}>{basisPrice(item2.price, item2.cost)}</td><td className={styles.td}>{Math.floor(basisPrice(item2.price, item2.cost) * 0.06)}</td><td className={styles.td}>{Math.floor(basisPrice(item2.price, item2.cost) * 1.06)}</td></tr>)}</Fragment>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}