import styles from './TemporarilyTable.module.css'
import { useState, useEffect, useContext } from 'react'
import { AuthContext, AuthContextType } from '../../Auth';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

type TemporarilyProduct = {
    temporarily_id: number;
    inventory_id: number;
    username: string;
    take_out_date: Date;
    manufacture: string;
    productname: string;
    left_right: string;
    size: string;
    color: string;
    quantity: number;
    office: string;
}

export default function TemporarilyTable() {

    const [temporarilyProducts, setTemporarilyProducts] = useState<TemporarilyProduct[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const { auth } = useContext(AuthContext) as AuthContextType;

    useEffect(() => {
        const fetchTemporarily = async () => {
            const response = await fetch("https://api.poweb.jp/gettemporarily")
            const data = await response.json();
            for (let i = 0; i < data.length; i++) {
                data[i].take_out_date = new Date(data[i].take_out_date);
            }
            setTemporarilyProducts(data);
        }
        fetchTemporarily();
    }, [reload])

    const dayofweek = ["（日）", "（月）", "（火）", "（水）", "（木）", "（金）", "（土）"];
    const convert = (dateItem: Date) => {
        const japanOffset = 9 * 60 * 60 * 1000;
        const japanDate = new Date(dateItem.getTime() + japanOffset);
        const year = japanDate.getFullYear();
        const month = japanDate.getMonth() + 1;
        const date = japanDate.getDate();
        const day = dayofweek[japanDate.getDay()];
        const hours = japanDate.getHours();
        const minutes = japanDate.getMinutes();
        const seconds = japanDate.getSeconds();
        const convertDate = {
            date: `${year}年${month}月${date}日${day}`,
            time: `${hours}: ${minutes}: ${seconds}`
        }
        return convertDate;
    }

    const returnInventory = async (index: number) => {
        const response = await fetch("https://api.poweb.jp/returninventory", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                temporarily_id: temporarilyProducts[index].temporarily_id,
                inventory_id: temporarilyProducts[index].inventory_id,
                quantity: temporarilyProducts[index].quantity
            })
        })
        const data = await response.json();
        alert(data.message);
        setReload(!reload);
    }

    const expendProduct = async (index: number) => {
        const response = await fetch("https://api.poweb.jp/usetemporarily", {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                temporarily_id: temporarilyProducts[index].temporarily_id,
                inventory_id: temporarilyProducts[index].inventory_id
            })
        })
        const data = await response.json();
        if (data.result) {
            alert(`一時出庫分の${temporarilyProducts[index].productname + temporarilyProducts[index].left_right + temporarilyProducts[index].size + temporarilyProducts[index].color}の使用処理が完了しました。`);
            setReload(!reload);
            return
        }
        if (data.result) {
            return alert("使用処理に失敗しました。")
        }

    }

    return (
        <div className={styles.div}>
            <h2>一時出庫リスト</h2>
            <Link to="/Temporarily"><Button variant="contained" size="large" sx={{ position: "absolute", top: 25, right: 50, fontSize: 16, "@media screen and (max-width:959px)": { top: 70, right: 10 } }} >一時出庫</Button></Link>

            {temporarilyProducts.length > 0 ? <div className={styles.scroll}><table className={styles.table}>
                <thead><tr>
                    <th className={styles.th}>支店</th>
                    <th className={styles.th}>使用者</th>
                    <th className={styles.th}>メーカー</th>
                    <th className={styles.th}>製品</th>
                    <th className={styles.th2}>左右</th>
                    <th className={styles.th2}>サイズ</th>
                    <th className={styles.th}>色</th>
                    <th className={styles.th2}>数量</th>
                    <th className={styles.th}>持ち出し日</th>
                </tr></thead>
                <tbody>
                    {temporarilyProducts.map((item, index) => <tr key={index} className={styles.tr}>
                        <td className={styles.td}>{item.office}</td>
                        <td className={styles.td}>{item.username}</td>
                        <td className={styles.td}>{item.manufacture}</td>
                        <td className={styles.td}>{item.productname}</td>
                        <td className={styles.td}>{item.left_right}</td>
                        <td className={styles.td}>{item.size}</td>
                        <td className={styles.td}>{item.color}</td>
                        <td className={styles.td}>{item.quantity}</td>
                        <td className={styles.td}>{`${convert(item.take_out_date).date + convert(item.take_out_date).time}`}</td>
                        {(item.username === auth.username) ? <td ><Button onClick={() => returnInventory(index)} sx={{ "@media screen and (max-width:959px)": { width: 100 } }}>在庫に戻す</Button></td> : null}
                        {(item.username === auth.username) ? <td ><Button onClick={() => expendProduct(index)} sx={{ "@media screen and (max-width:959px)": { width: 100 } }}>使用する</Button></td> : null}
                    </tr>)}
                </tbody>
            </table></div> : <p>一時出庫中の製品はありません。</p>}
        </div>
    )
}