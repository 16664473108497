import { Button } from "@mui/material";
import { utils, write } from 'xlsx';

type Props = {
    data: string[][];
}

export default function ExcelDownloadButton(props: Props) {

    const download = () => {
        const sheet = utils.aoa_to_sheet(props.data);

        const book = utils.book_new();
        const sheetName = '価格表'
        utils.book_append_sheet(book, sheet, sheetName);

        const binary: Uint8Array = write(book, { type: 'array' });
        const blob = new Blob([binary], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const fileName = '価格表';
        // saveAs(blob,fileName);
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
    }
    return (
        <Button variant="contained" onClick={download} >Excelダウンロード</Button>
    )
}