import { useContext, useEffect, ReactNode, FC } from 'react';
import { Route, Routes, BrowserRouter, useNavigate } from "react-router-dom"
import './App.css';
import { AuthContext, AuthProvider, AuthContextType } from './Auth';
import Sidebar from './component/Sidebar';
import Home from './page/Home/Home';
import SupplierRegister from './page/Suppliers/SupplierRegister';
import SupplierTable from './page/Suppliers/SupplierTable'
import Suppliers from './page/Suppliers/Suppliers';
import Products from './page/Products/Products';
import ProductRegister from './page/Products/ProductRegister'
import Constant from "./page/Constant/Constant";
import ConstantRegister from "./page/Constant/ConstantRegister";
import Control from "./page/Control/Control";
import Entering from "./page/Entering/Entering"
import Order from "./page/Order/Order";
import OrderConfirm from "./page/Order/OrderConfirm";
import ProductEditor from "./page/Products/ProductEditor";
import SupplierEditor from "./page/Suppliers/SupplierEditor";
import Manufacture from "./page/Manufacture/Manufacture";
import History from "./page/History/History";
import HistoryDetail from "./page/History/HistoryDetail";
import Ordered from "./page/Ordered/Ordered";
import AutomaticOrderingTable from "./page/AutomaticOrdering/AutomaticOrderingTable";
import AutomaticOrdering from "./page/AutomaticOrdering/AutomaticOrdering";
import Login from "./page/Login/Login";
import PriceList from "./page/PriceList/PriceList"
import Temporarily from './page/Temporarily/Temporarily';
import TemporarilyTable from './page/Temporarily/TemporarilyTable';
import Reservation from './page/Reservation/Reservation';


interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { auth } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated === false) {
      navigate('/login');
    }
  }, [auth.isAuthenticated, navigate]);

  // 認証状態が不確定な場合は子コンポーネントをレンダリングしない
  if (auth.isAuthenticated === false || auth.isAuthenticated === null) {
    return null;
  }
  return <>{children}</>;
};


function App() {
  return (
    <AuthProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="*"
              element={
                <>
                  <Sidebar />
                  <Routes>
                    <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    <Route path="/Suppliers" element={<ProtectedRoute><Suppliers /></ProtectedRoute>} />
                    <Route path="/SupplierRegister" element={<ProtectedRoute><SupplierRegister /></ProtectedRoute>} />
                    <Route path="/SupplierEditor" element={<ProtectedRoute><SupplierEditor /></ProtectedRoute>} />
                    <Route path="/SupplierTable" element={<ProtectedRoute><SupplierTable /></ProtectedRoute>} />
                    <Route path="/Products" element={<ProtectedRoute><Products /></ProtectedRoute>} />
                    <Route path="/ProductRegister" element={<ProtectedRoute><ProductRegister /></ProtectedRoute>} />
                    <Route path="/Constant" element={<ProtectedRoute><Constant /></ProtectedRoute>} />
                    <Route path="/ConstantRegister" element={<ProtectedRoute><ConstantRegister /></ProtectedRoute>} />
                    <Route path="/Control" element={<ProtectedRoute><Control /></ProtectedRoute>} />
                    <Route path="/Entering" element={<ProtectedRoute><Entering /></ProtectedRoute>} />
                    <Route path="/Order" element={<ProtectedRoute><Order /></ProtectedRoute>} />
                    <Route path="/OrderConfirm" element={<ProtectedRoute><OrderConfirm /></ProtectedRoute>} />
                    <Route path="/History" element={<ProtectedRoute><History /></ProtectedRoute>} />
                    <Route path="/ProductEditor" element={<ProtectedRoute><ProductEditor /></ProtectedRoute>} />
                    <Route path="/Manufacture" element={<ProtectedRoute><Manufacture /></ProtectedRoute>} />
                    <Route path="/HistoryDetail" element={<ProtectedRoute><HistoryDetail /></ProtectedRoute>} />
                    <Route path="/Ordered" element={<ProtectedRoute><Ordered /></ProtectedRoute>} />
                    <Route path="/AutomaticOrderingTable" element={<ProtectedRoute><AutomaticOrderingTable /></ProtectedRoute>} />
                    <Route path="/AutomaticOrdering" element={<ProtectedRoute><AutomaticOrdering /></ProtectedRoute>} />
                    <Route path="/Pricelist" element={<ProtectedRoute><PriceList /></ProtectedRoute>} />
                    <Route path="/Temporarily" element={<ProtectedRoute><Temporarily /></ProtectedRoute>} />
                    <Route path="/TemporarilyTable" element={<ProtectedRoute><TemporarilyTable /></ProtectedRoute>} />
                    <Route path="/Reservation" element={<ProtectedRoute><Reservation /></ProtectedRoute>} />

                  </Routes>
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
