import styles from './Ordered.module.css'
import { Button } from '@mui/material'
import { useState, useEffect } from 'react'
import { SupplierData } from '../Suppliers/SupplierTable'

type product = {
    productname: string;
    left_right: string;
    color: string;
    size: string;
    quantity: number;
}

type orderedData = {
    office: string;
    order_at: string;
    order_id: number;
    products: product[];
    supplier: string;
}

export default function Ordered() {

    const [selectedOffice, setSelectedOffice] = useState<string>("");
    const [suppliers, setSuppliers] = useState<SupplierData[]>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<string>("");
    const [ordered, setOrdered] = useState<orderedData[]>([]);

    useEffect(() => {

        const fetchSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier")
            const data = await response.json();
            setSuppliers(data);
        }

        const fetchOrderedProduct = async () => {
            const response = await fetch("https://api.poweb.jp/orderedproduct", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    office: selectedOffice,
                    supplier: selectedSupplier
                })
            })
            const data = await response.json();
            for (let i = 0; i < data.length; i++) {
                const order_at = new Date(data[i].order_at);
                const dayofweek = ["（日）", "（月）", "（火）", "（水）", "（木）", "（金）", "（土）"];
                const convert = (dateItem: Date) => {
                    const japanOffset = 9 * 60 * 60 * 1000;
                    const japanDate = new Date(dateItem.getTime() + japanOffset);
                    const year = japanDate.getFullYear();
                    const month = japanDate.getMonth() + 1;
                    const date = japanDate.getDate();
                    const day = dayofweek[japanDate.getDay()];
                    const hours = japanDate.getHours();
                    const minutes = japanDate.getMinutes();
                    const seconds = japanDate.getSeconds();
                    const convertDate = {
                        date: `${year}年${month}月${date}日${day}`,
                        time: `${hours}: ${minutes}: ${seconds}`
                    }
                    return convertDate;
                }
                const convertdata = convert(order_at);
                data[i].order_at = convertdata.date + convertdata.time;
            }
            setOrdered(data);
        }

        fetchSupplier();
        fetchOrderedProduct();
    }, [selectedOffice, selectedSupplier])

    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSupplier(e.target.value);
    }

    const orderedEnter = async (index: number) => {
        const enterConform = window.confirm("選択した未入庫分製品の入庫処理を行い、発注履歴に納品日を登録します。よろしいですか？")
        if (enterConform) {
            try {
                const response = await fetch("https://api.poweb.jp/orderedenter", {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        order_id: ordered[index].order_id
                    })
                })
                const data = await response.json();
                if (data.success) {
                    const response = await fetch("https://api.poweb.jp/submitentering", {
                        method: "PUT",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            order_id: ordered[index].order_id
                        })
                    })
                    const data = await response.json();
                    console.log(data.result)
                    alert("入庫処理と納品日の登録が完了しました。")
                }
                const fetchOrderedProduct = async () => {
                    const response = await fetch("https://api.poweb.jp/orderedproduct", {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            office: selectedOffice,
                            supplier: selectedSupplier
                        })
                    })
                    const data = await response.json();
                    for (let i = 0; i < data.length; i++) {
                        const order_at = new Date(data[i].order_at);
                        const dayofweek = ["（日）", "（月）", "（火）", "（水）", "（木）", "（金）", "（土）"];
                        const convert = (dateItem: Date) => {
                            const japanOffset = 9 * 60 * 60 * 1000;
                            const japanDate = new Date(dateItem.getTime() + japanOffset);
                            const year = japanDate.getFullYear();
                            const month = japanDate.getMonth() + 1;
                            const date = japanDate.getDate();
                            const day = dayofweek[japanDate.getDay()];
                            const hours = japanDate.getHours();
                            const minutes = japanDate.getMinutes();
                            const seconds = japanDate.getSeconds();
                            const convertDate = {
                                date: `${year}年${month}月${date}日${day}`,
                                time: `${hours}: ${minutes}: ${seconds}`
                            }
                            return convertDate;
                        }
                        const convertdata = convert(order_at);
                        data[i].order_at = convertdata.date + convertdata.time;
                    }
                    setOrdered(data);
                }
                fetchOrderedProduct();
            } catch (error) {
                alert("入庫処理に失敗しました。")
                console.error(error)
            }
        } else {
            alert("入庫と納品日の登録をキャンセルしました。")
        }
    }

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>発注済み未入庫</h2>
            <label htmlFor="office">支店：
                <select id="office" onChange={handleOffice} value={selectedOffice} className={styles.select}>
                    <option value="">未設定</option>
                    <option value="函館店">函館店</option>
                    <option value="札幌店">札幌店</option>
                    <option value="釧路店">釧路店</option>
                    <option value="室蘭店">室蘭店</option>
                </select></label>
            <label htmlFor="supplier">発注先：
                <select id="supplier" onChange={handleSupplier} value={selectedSupplier} className={styles.select}>
                    <option value="">未設定</option>
                    {suppliers.map((item, index) =>
                        <option value={item.supplier} key={index}>{item.supplier}</option>
                    )}
                </select></label>
            <div className={styles.scroll}>
                {ordered.length > 0 ? ordered.map((item: orderedData, index) => (<table key={index} className={styles.table}><thead><tr><th className={styles.th}>発注日：{item.order_at}</th><th className={styles.th}>発注元：{item.office}</th><th className={styles.th} colSpan={3}>発注先：{item.supplier}</th></tr></thead>
                    <tbody>{item.products.map((item2: product, index2) => (<tr key={index2} className={styles.tr}><td className={styles.td}>{item2.productname}</td><td className={styles.td}>{item2.color}</td><td className={styles.td}>{item2.left_right}</td><td className={styles.td}>{item2.size}</td><td className={styles.td}>{item2.quantity}</td></tr>))}
                        <tr><td colSpan={5}><Button variant="contained" onClick={() => orderedEnter(index)}>入庫</Button></td></tr></tbody>
                </table>)) : <p>条件の合う発注済み未入庫が見つかりません。</p>}
            </div>
        </div>
    )
}