import styles from './AutomaticOrderingTable.module.css'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { SupplierData } from "../Suppliers/SupplierTable";
import { Link } from "react-router-dom"


type Setting = {
    id: number;
    office: string;
    supplier: string;
    schedule: string;
    trigger: string;
    detailTrigger: string;
    supplierConstant: number;
    triggerProducts: string[];
    productConstant: number[];
};

export default function AutomaticOrderingTable() {

    const [automaticOrdering, setAutomaticOrdering] = useState<Setting[]>([]);
    const [selectedOffice, setSelectedOffice] = useState<string>();
    const [selectSupplier, setSelectSupplier] = useState<SupplierData[]>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<string>();


    useEffect(() => {
        const fetchSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier")
            const data = await response.json();
            setSelectSupplier(data);
        }
        fetchSupplier();
    }, []);

    useEffect(() => {
        const fetchAutomaticOrdering = async () => {
            const response = await fetch("https://api.poweb.jp/getautomatic", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    office: selectedOffice,
                    supplier: selectedSupplier
                })
            })
            const data = await response.json();

            let settings: Setting[] = [];
            for (let i = 0; data.length > i; i++) {
                settings.push({
                    id: data[i].automatic_id,
                    office: data[i].office,
                    supplier: data[i].supplier,
                    schedule: data[i].schedule,
                    trigger: data[i].trigger,
                    detailTrigger: data[i].detailTrigger,
                    supplierConstant: data[i].supplierConstant,
                    triggerProducts: JSON.parse(data[i].detailProduct).triggerProducts,
                    productConstant: JSON.parse(data[i].detailProduct).productConstant
                })
            }
            console.log(settings)
            setAutomaticOrdering(settings);
        }
        fetchAutomaticOrdering();

    }, [selectedOffice, selectedSupplier])

    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSupplier(e.target.value);
    }

    const handleDelete = async (index: number) => {
        const id = automaticOrdering[index].id;
        const response = await fetch("https://api.poweb.jp/deleteautomatic", {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: id })
        })
        const data = await response.json();
        alert(data.message);
        let newAO = [...automaticOrdering];
        newAO.splice(index, 1);
        setAutomaticOrdering(newAO);

    }

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>自動発注管理</h2>
            <Link to="/AutomaticOrdering"><Button variant="contained" size="large" sx={{ position: "absolute", top: 20, right: 50, fontSize: 16, "@media screen and (max-width:959px)": { top: 70, right: 10, fontSize: 14 } }}>自動発注を新規登録する</Button></Link>
            <div><select value={selectedOffice} id="支店" onChange={handleOffice} className={styles.select}>
                <option value="">支店</option>
                <option value="函館店">函館店</option>
                <option value="札幌店">札幌店</option>
                <option value="釧路店">釧路店</option>
                <option value="室蘭店">室蘭店</option>
            </select>
                <select onChange={handleSupplier} className={styles.select}>
                    <option value="">発注先</option>
                    {selectSupplier ? selectSupplier.map((item, index) => (<option key={index} value={item.supplier}>{item.supplier}</option>)) : null}
                </select>
            </div>
            {automaticOrdering ? automaticOrdering.map((item: Setting, index: number) =>
                <div className={styles.div2} key={index}>
                    <table className={styles.table}>
                        <thead><tr><th className={styles.th}>発注元</th><th className={styles.th}>発注先</th><th className={styles.th}>確認スケジュール</th><th className={styles.th}>発注条件</th></tr></thead>
                        <tbody>
                            <tr className={styles.tr}><td className={styles.td}>{item.office}</td><td className={styles.td}>{item.supplier}</td><td className={styles.td}>{item.schedule}</td><td className={styles.td}> {(item.trigger === "timing") && "条件なし"}{(item.trigger === "carriage") && "送料無料額で発注"}</td></tr>
                            {item.detailTrigger ? <tr className={styles.tr}><td className={styles.td}>詳細設定：{(item.detailTrigger === "supplierTrigger") && "定数設定のある製品すべて"}{(item.detailTrigger === "productTrigger") && "指定の製品"}</td>{(item.detailTrigger === "supplierTrigger") && <td className={styles.td} colSpan={3}>{`定数が設定されている製品残数が ${item.supplierConstant} 個以下のものがあれば発注`}</td>}{(item.detailTrigger === "productTrigger") && <td className={styles.td} colSpan={3}>{item.triggerProducts.map((product, index2) => <p key={index2} className={styles.tdp}>{`${product} が ${item.productConstant[index2]} 個以下の場合発注`}</p>)}</td>}</tr> : null}
                        </tbody>
                    </table>
                    <Button onClick={() => handleDelete(index)} sx={{ fontSize: 16, "@media screen and (max-width:959px)": { fontSize: 14 } }}>取り消し</Button>
                </div>) : <p className={styles.p}>登録されているデータはありません。</p>}
        </div>
    )
}