import { useState, useEffect, useContext } from "react"
import { AuthContext, AuthContextType } from '../../Auth';
import styles from "./Login.module.css"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function Login() {

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const navigate = useNavigate();

    const { auth, setAuth } = useContext(AuthContext) as AuthContextType;
    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate("/");
        }
    }, [auth, navigate])

    const handleLogin = async () => {
        try {
            const response = await fetch('https://api.poweb.jp/login', {
                method: "POST",
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: username, password: password })
            });
            const data = await response.json();
            alert(data.message);
            if (data.result) {
                setAuth({ username, isAuthenticated: true });
                navigate("/");
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <div className={styles.div}>
            <form className={styles.form}>
                <h3>ログインフォーム</h3>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    className={styles.input}
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className={styles.input}
                />
                <Button onClick={handleLogin} variant="contained" style={{ margin: 10 }}>ログイン</Button>
            </form>
        </div>
    );



}