import Calendar from "../../component/Calendar";
import styles from "./Reservation.module.css"

export type ReservationType = {
    reservation_id: number;
    application_date: string;
    productname: string;
    color: string;
    left_right: string;
    size: string;
    quantity: number;
    username: string;
    reservation_date: { year: number; month: number; date: number; }
    prepared: boolean;
}

export default function Reservation() {

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>出庫予約カレンダー</h2>
            <Calendar />
        </div>
    )
}