import { useEffect, useState } from "react"
import styles from "./Control.module.css"
import { inventoryProducts } from "../Home/Home"
import { Button } from "@mui/material";

export default function Control() {
    const [products, setProducts] = useState<inventoryProducts[]>([]);
    const [selectedOffice, setSelectedOffice] = useState<string>("函館店");
    const [selectManufacture, setSelectManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>("");
    const [selectedMoldingSite, setSelectedMoldingSite] = useState<string>("");
    const [selectProduct, setSelectProduct] = useState<string[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string>("");


    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures = data.manufacture.map((item: Object) => Object.values(item))
            setSelectManufacture(Manufactures);
        }
        fetchSelectInit();
    }, [])

    useEffect(() => {
        const fetchProduct = async () => {
            const response = await fetch("https://api.poweb.jp/product", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "manufacture": selectedManufacture,
                    "moldingsite": selectedMoldingSite
                })
            });
            const data = await response.json();
            setSelectProduct(data);
        }
        fetchProduct();
    }, [selectedManufacture, selectedMoldingSite])


    useEffect(() => {

        const fetchProducts = async () => {
            const response = await fetch("https://api.poweb.jp/", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "office": selectedOffice,
                    "manufacture": selectedManufacture,
                    "moldingsite": selectedMoldingSite,
                    "productname": selectedProduct
                })
            });
            const data = await response.json();
            setProducts(data);
        }

        fetchProducts();
    }, [selectedOffice, selectedManufacture, selectedMoldingSite, selectedProduct])


    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleMoldingSite = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMoldingSite(e.target.value);
        setSelectedProduct("");
    }

    const handleProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value);
    }

    const increment = async (index: number) => {
        const incrementItem = products[index];
        const response = await fetch("https://api.poweb.jp/increment", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(incrementItem)
        })
        const data = await response.json();
        console.log(data);
        let incrementdata = [...products];
        incrementdata[index].quantity += 1;
        setProducts(incrementdata);
    }

    const decrement = async (index: number) => {
        const decrementItem = products[index];
        const response = await fetch("https://api.poweb.jp/decrement", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(decrementItem)
        })
        const data = await response.json();
        console.log(data);
        let decrementdata = [...products];
        decrementdata[index].quantity -= 1;
        setProducts(decrementdata);
    }

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>在庫リスト</h2>
            <div className={styles.selectwrapper}>
                <label htmlFor="支店" className={styles.label}>支店
                    <select value={selectedOffice} id="支店" onChange={handleOffice} className={styles.select}>
                        <option value="函館店">函館店</option>
                        <option value="札幌店">札幌店</option>
                        <option value="釧路店">釧路店</option>
                        <option value="室蘭店">室蘭店</option>
                    </select>
                </label>
                <label htmlFor="メーカー" className={styles.label}>メーカー
                    <select value={selectedManufacture} id="メーカー" onChange={handleManufacture} className={styles.select}>
                        <option value="">未設定</option>
                        {selectManufacture.map(item => (<option key={item} value={item}>{item}</option>))}
                    </select>
                </label>
                <label htmlFor="装着部位" className={styles.label}>装着部位
                    <select value={selectedMoldingSite} id="装着部位" onChange={handleMoldingSite} className={styles.select}>
                        <option value="">未設定</option>
                        <option value="A%">下肢装具</option>
                        <option value="D%">上肢装具</option>
                        <option value="C%">体幹装具</option>
                    </select>
                </label>
                <label htmlFor="製品" className={styles.label}>製品
                    <select value={selectedProduct} id="製品" onChange={handleProduct} className={styles.select}>
                        <option value="">未設定</option>
                        {selectProduct.map(item => (<option key={item} value={item}>{item}</option>))}
                    </select>
                </label>
            </div>
            <div className={styles.scroll}>
                <table className={styles.table}>
                    <thead>
                        <tr><th className={styles.th1}>メーカー</th>
                            <th className={styles.th1}>製品名</th>
                            <th className={styles.th2}>色</th>
                            <th className={styles.th3}>左右</th>
                            <th className={styles.th3}>サイズ</th>
                            <th className={styles.th3}>在庫数</th></tr>
                    </thead>
                    <tbody>
                        {products.map((item, index) => (<tr key={index} className={styles.tr}>
                            <td className={styles.td}>{item.manufacture}</td>
                            <td className={styles.td}>{item.productname}</td>
                            <td className={styles.td}>{item.color}</td>
                            <td className={styles.td}>{item.left_right}</td>
                            <td className={styles.td}>{item.size}</td>
                            <td className={styles.td}>{item.quantity}</td>
                            <td className={styles.td2}><Button variant="contained" size="small" key={index} onClick={() => increment(index)} >入庫</Button></td>
                            <td className={styles.td2}><Button variant="contained" size="small" key={index} onClick={() => decrement(index)} disabled={item.quantity < 1}>出庫</Button></td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}