import { useState, useEffect } from "react"
import { Button } from "@mui/material";
import styles from "./Manufacture.module.css"
import { useForm, SubmitHandler } from "react-hook-form";

type Manufacturetype = {
    manufacture: string;
}

export default function Manufacture() {
    const [manufactures, setManufactures] = useState<string[]>([]);



    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufacture = data.manufacture.map((item: Object) => Object.values(item))
            setManufactures(Manufacture);
        }
        fetchSelectInit();
    }, [manufactures])

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Manufacturetype>(
        { mode: 'onBlur' }
    )

    const onSubmit: SubmitHandler<Manufacturetype> = async (data) => {
        const datastr = JSON.stringify(data);
        const response = await fetch("https://api.poweb.jp/createmanufacture", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: datastr
        })
        const resjson = await response.json();
        alert(resjson.message);
        reset();
    }

    const handleDelete = async (index: number) => {
        const deletename = manufactures[index];
        if (window.confirm(`メーカーを${deletename}で登録している製品もすべて削除されます。${deletename}を削除してもよろしいですか？在庫にある製品のみ残ります。`)) {
            try {
                const response = await fetch("https://api.poweb.jp/deletemanufacture", {
                    method: "DELETE",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "deletemanufacture": deletename })
                })
                if (!response.ok) {
                    throw new Error("Network response was not OK");
                }
                alert("削除に成功しました。");
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("削除をキャンセルしました。")
        }
    }

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>メーカー登録</h2>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" className={styles.input} {...register("manufacture",
                    {
                        required: "メーカーの名称は必須です。",
                        maxLength: {
                            value: 50, message: "50文字以内で入力してください。"
                        },
                        pattern: { value: /^[Ａ-Ｚ\u4E00-\u9FFFぁ-んァ-ヶ０-９ー・　]+$/, message: "全角で入力してください。" },
                    })} />
                <span className={styles.span}>{errors.manufacture?.message}</span>
                <Button type="submit" variant="contained" style={{ fontSize: 16, margin: 15 }} >登録</Button>
            </form>
            <table className={styles.table}>
                <thead><tr><th className={styles.th}>メーカー一覧</th></tr></thead>
                <tbody>
                    {manufactures && manufactures.map((item, index) => (<tr key={index} className={styles.tr}><td className={styles.td}>{item}</td><td><Button key={"delete" + index} onClick={() => handleDelete(index)} style={{ fontSize: 16, margin: 5 }}>削除</Button></td></tr>))}
                </tbody>
            </table>
        </div>
    )
}