import { useEffect, useState } from "react"
import { Button } from "@mui/material";
import styles from "./Products.module.css"
import { Link, useNavigate } from "react-router-dom"
import { productData } from "../Constant/ConstantRegister"

export default function Products() {

    const navigate = useNavigate();

    const [selectManufacture, setSelectManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>("");
    const [products, setProducts] = useState<productData[]>([]);

    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures: string[] = data.manufacture.map((item: Object) => Object.values(item))
            setSelectManufacture(Manufactures);
        }
        fetchSelectInit();

    }, [])

    useEffect(() => {

        const fetchProducts = async () => {
            const response = await fetch("https://api.poweb.jp/getproducts", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "manufacture": selectedManufacture })
            })
            const data = await response.json();
            let newProducts = [];
            for (let i = 0; data.length > i; i++) {
                const newData = JSON.parse(data[i].specification);
                console.log(newData);
                const newProduct: productData = {
                    product_id: data[i].product_id,
                    productname: data[i].productname,
                    manufacture: data[i].manufacture,
                    moldingsite: data[i].moldingsite,
                    supplier: data[i].supplier,
                    cost: data[i].cost,
                    leftright: newData.leftright,
                    size: newData.size,
                    color: newData.color
                }
                newProducts.push(newProduct);
            }
            setProducts(newProducts);
        }

        fetchProducts();
    }, [selectedManufacture]);


    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleEdit = (index: number) => {
        const editProduct = products[index];
        console.log(editProduct);
        navigate("/ProductEditor", { state: editProduct });
    }

    const handleDelete = async (index: number) => {
        const deleteproduct = products[index].productname;
        if (window.confirm(`${deleteproduct}を削除します。よろしいですか？`)) {
            try {
                const response = await fetch("https://api.poweb.jp/deleteproduct", {
                    method: "DELETE",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "deleteproduct": deleteproduct })
                })
                if (!response.ok) {
                    throw new Error("Network response was not OK");
                }
                alert("削除に成功しました。");
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("削除をキャンセルしました。")
        }
    }

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>製品リスト</h2>
            <Link to="/ProductRegister"><Button variant="contained" size="large" sx={{ position: "absolute", top: 25, right: 50, fontSize: 16, "@media screen and (max-width:959px)": { top: 70, right: 10 } }} >製品を新規登録する</Button></Link>
            <select name="selectManufacture" value={selectedManufacture} onChange={handleManufacture} className={styles.select}>
                <option value="" hidden>メーカー</option>
                {selectManufacture.map((item, index) => (
                    <option key={index} value={item}>{item}</option>))}
            </select>
            <div className={styles.scroll}>
                {(products.length > 0) ? (<table className={styles.table}>
                    <thead><tr><th className={styles.th}>製品名</th><th className={styles.th}>値段</th><th className={styles.th}>採型区分</th><th className={styles.th}>左右</th><th className={styles.th}>サイズ</th><th className={styles.th}>色</th></tr></thead>
                    <tbody>
                        {products.map((item, index) => (
                            <tr key={index} className={styles.tr}><td className={styles.td}>{item.productname}</td><td className={styles.td}>{item.cost}</td><td className={styles.td}>{item.moldingsite}</td><td className={styles.td}>{item.leftright.map((item2, index2) => (<div key={index2}>{item2}</div>))}</td><td className={styles.td}>{item.size.map((item2, index3) => <div key={index3}>{item2}</div>)}</td><td className={styles.td}>{item.color.map((item2, index4) => (<div key={index4}>{item2}</div>))}</td><td className={styles.td2}><Button onClick={() => handleEdit(index)}>編集</Button></td><td className={styles.td2}><Button onClick={() => handleDelete(index)}>削除</Button></td></tr>
                        ))}
                    </tbody>
                </table>) : <p className={styles.p}>メーカーを選択してください。</p>}
            </div>
        </div>
    )
}