import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./ProductEditor.module.css";
import { ProductRegisterData } from "./ProductRegister"
import { useEffect, useState } from "react";
import { useMemo } from 'react';

export default function ProductEditor() {

    const navigate = useNavigate();
    const location = useLocation();
    const editData = location.state;
    console.log(editData);

    const LeftRight = (editData.leftright[0] === "") ? '[""]' : '["左","右"]';
    const LeftRight2 = (editData.leftright[0] === "") ? '["左","右"]' : '[""]';
    const LeftRight3 = (editData.leftright[0] === "") ? "左右無し" : "左右有り";
    const LeftRight4 = (editData.leftright[0] === "") ? "左右有り" : "左右無し";
    const editSize = useMemo(() => {
        return editData.size.join(',');
    }, [editData.size]);

    const editColor = useMemo(() => {
        return editData.color.join(',');
    }, [editData.color]);

    const initialvalues = useMemo(() => ({
        productname: editData.productname,
        manufacture: editData.manufacture,
        supplier: editData.supplier,
        moldingsite: editData.moldingsite,
        cost: editData.cost,
        leftright: LeftRight,
        size: editSize,
        color: editColor
    }), [editData.productname, editData.manufacture, editData.supplier, editData.moldingsite, editData.cost, LeftRight, editSize, editColor]);

    const [suppliers, setSuppliers] = useState<string[]>();
    const [manufactures, setManufactures] = useState<string[]>();
    const [moldingsite, setMoldingsite] = useState<string[]>();

    console.log(initialvalues.leftright);

    useEffect(() => {
        const fetchSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier")
            const data = await response.json();
            let suppliername: string[] = [];
            for (let i = 0; i < data.length; i++) {
                suppliername = [...suppliername, data[i].supplier]
            }
            const newSupplierName = suppliername.filter(value => {
                return initialvalues.supplier !== value
            })
            setSuppliers(newSupplierName);
        }

        const fetchmoldingsite = async () => {
            const response = await fetch("https://api.poweb.jp/moldingsite");
            const data = await response.json();
            const newMoldingsite = data.filter((value: string) => {
                return initialvalues.moldingsite !== value;
            })
            setMoldingsite(newMoldingsite);
        }

        const fetchManufacture = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufacture = data.manufacture.map((item: Object) => Object.values(item));
            const newManufacture = Manufacture.filter((value: string) => {
                return initialvalues.manufacture !== value;
            })
            setManufactures(newManufacture);
        }

        fetchSupplier();
        fetchmoldingsite();
        fetchManufacture();
    }, [initialvalues.manufacture, initialvalues.moldingsite, initialvalues.supplier])


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ProductRegisterData>(
        {
            mode: 'onBlur',
            defaultValues: initialvalues
        }
    )

    const onSubmit: SubmitHandler<ProductRegisterData> = async (data) => {
        const idplus = { ...data, product_id: editData.product_id }
        const datastr = JSON.stringify(idplus);
        const response = await fetch("https://api.poweb.jp/updateProduct", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: datastr
        })
        const resjson = await response.json();
        alert(resjson.message);
        if (resjson.submit) {
            navigate("/Products");
        }
    }

    return (

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h2>製品の登録</h2>
            <p>すべての項目を入力してください。</p>
            <p>製品の名称を入力してください。<br />
                （全角で入力してください。）</p>
            <input type="text"
                {...register("productname",
                    {
                        required: "製品の名称は必須です。",
                        maxLength: {
                            value: 50, message: "50文字以内で入力してください。"
                        },
                        pattern: { value: /^[Ａ-Ｚ\u4E00-\u9FFFぁ-んァ-ヶ０-９ー・　]+$/, message: "全角で入力してください。" },
                    })} className={styles.input} />
            <span>{errors.productname?.message}</span>

            <p>製品のメーカー名を選択してください。</p>
            <select {...register("manufacture", { required: "メーカーの名称は必須です。" })} className={styles.select} >
                <option value={initialvalues.manufacture}> {initialvalues.manufacture}</option>
                {manufactures ? manufactures.map((item, index) => <option key={index} value={item}>{item}</option>) : null}
            </select>
            <span>{errors.manufacture?.message}</span>

            <p>製品の発注先を選択してください。</p>
            <select {...register("supplier", { required: "必ず選択してください。" })} className={styles.select}>
                <option value={initialvalues.supplier}>{initialvalues.supplier}</option>
                {suppliers ? suppliers.map((item, index) => (<option key={index} value={item}>{item}</option>)) : null}
            </select>
            <span>{errors.supplier?.message}</span>

            <p>製品の採型区分を選択してください。</p>
            <select {...register("moldingsite", { required: "必ず選択してください。" })} className={styles.select}>
                <option value={initialvalues.moldingsite}>{initialvalues.moldingsite}</option>
                {moldingsite ? moldingsite.map((item, index) => (<option key={index} value={item}>{item}</option>)) : null}
            </select>
            <span>{errors.moldingsite?.message}</span>

            <p>仕入れ値を入力してください。<br />
                （半角数字で入力してください。）</p>
            <input type="number" {...register("cost", {
                required: "仕入れ値は必須です。"
            })} className={styles.input} />
            <span>{errors.cost?.message}</span>
            <p>左右の有無を選択してください。</p>
            <select {...register("leftright")} className={styles.select}>
                <option value={LeftRight}>{LeftRight3}</option>
                <option value={LeftRight2}>{LeftRight4}</option>
            </select>
            <p>サイズを入力してください。サイズ区分がない場合は未入力で進んでください。<br />
                （半角の　,　で区切ってすべてのサイズを入力してください。<br />
                カナは全角、アルファベットと数字は半角で入力してください。）<br />
                例）S,M,L,LL   S,M-L,ショートS,ショートM-L　等</p>

            <input type="text"
                {...register("size",
                    {
                        maxLength: {
                            value: 50, message: "50字以内で表現してください。"
                        },
                        pattern: { value: /^[\u4E00-\u9FFFA-Zァ-ヶ0-9\-ー,]+$/, message: "アルファベットの大文字、全角カナ、半角数字で入力してください。" },
                    })} className={styles.input} />
            <span>{errors.size?.message}</span>
            <p>色を入力してください。色区分がない場合は未入力で進んでください。<br />
                （半角の　,　で区切ってすべてのサイズを入力してください。<br />
                全角カナか漢字で入力してください。）<br />
                例）ベージュ、ブラック 黒  等</p>

            <input type="text"
                {...register("color",
                    {
                        maxLength: {
                            value: 20, message: "20字以内で表現してください。"
                        },
                        pattern: {
                            value: /^[\u4E00-\u9FFFァ-ヶー,]+$/, message: "漢字か全角カタカナで入力してください。"
                        },
                    })} className={styles.input} />
            <span>{errors.color?.message}</span>

            <Button type="submit" variant="contained" style={{ margin: 15 }}>登録</Button>
            <Link to="/Products"><Button>キャンセル</Button></Link>
        </form>
    )
}