import styles from './Temporarily.module.css';
import { useState, useEffect, useContext } from 'react'
import { inventoryProducts } from '../Home/Home';
import { Button } from '@mui/material';
import { AuthContext, AuthContextType } from '../../Auth';
import { Link } from 'react-router-dom';

export default function Temporarily() {

    const [products, setProducts] = useState<inventoryProducts[]>([]);
    const [selectedOffice, setSelectedOffice] = useState<string>("函館店");
    const [selectManufacture, setSelectManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>("");
    const [selectedMoldingSite, setSelectedMoldingSite] = useState<string>("");
    const [selectProduct, setSelectProduct] = useState<string[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string>("");
    const [bringoutProducts, setBringoutProducts] = useState<inventoryProducts[]>([]);
    const { auth } = useContext(AuthContext) as AuthContextType;


    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures = data.manufacture.map((item: Object) => Object.values(item))
            setSelectManufacture(Manufactures);
        }
        fetchSelectInit();
    }, [])

    useEffect(() => {
        const fetchProduct = async () => {
            const response = await fetch("https://api.poweb.jp/product", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "manufacture": selectedManufacture,
                    "moldingsite": selectedMoldingSite
                })
            });
            const data = await response.json();
            setSelectProduct(data);
        }
        fetchProduct();
    }, [selectedManufacture, selectedMoldingSite])


    useEffect(() => {

        const fetchProducts = async () => {
            const response = await fetch("https://api.poweb.jp/", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "office": selectedOffice,
                    "manufacture": selectedManufacture,
                    "moldingsite": selectedMoldingSite,
                    "productname": selectedProduct
                })
            });
            const data = await response.json();
            setProducts(data);
        }

        fetchProducts();
    }, [selectedOffice, selectedManufacture, selectedMoldingSite, selectedProduct])


    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleMoldingSite = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMoldingSite(e.target.value);
        setSelectedProduct("");
    }

    const handleProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value);
    }

    const handleBringoutProduct = (index: number) => {
        let newBringoutProducts = [...bringoutProducts];
        const sameIndex = newBringoutProducts.findIndex((element: inventoryProducts) => element.inventory_id === products[index].inventory_id);
        if (sameIndex >= 0) {
            newBringoutProducts[sameIndex].quantity += 1;
        } else {
            newBringoutProducts.push({
                inventory_id: products[index].inventory_id,
                color: products[index].color,
                constant: products[index].constant,
                left_right: products[index].left_right,
                manufacture: products[index].manufacture,
                office: products[index].office,
                productname: products[index].productname,
                size: products[index].size,
                supplier: products[index].supplier,
                quantity: 1
            })
        }
        setBringoutProducts(newBringoutProducts);
        let newProducts = [...products];
        newProducts[index].quantity -= 1;
        setProducts(newProducts);
    }

    const handleBack = (index: number) => {
        let newProducts = [...products];
        const sameIndex = newProducts.findIndex((element: inventoryProducts) => element.inventory_id === bringoutProducts[index].inventory_id);
        newProducts[sameIndex].quantity += 1;
        setProducts(newProducts);
        let newBringoutProducts = [...bringoutProducts];
        newBringoutProducts[index].quantity -= 1;
        if (newBringoutProducts[index].quantity < 1) {
            newBringoutProducts.splice(index, 1);
        }
        setBringoutProducts(newBringoutProducts);
    }

    const handleBringout = async () => {
        if (bringoutProducts.length < 1) {
            return alert("一時出庫する製品が選択されていません。");
        }
        const response = await fetch("https://api.poweb.jp/bringout", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: auth.username, products: bringoutProducts })
        });
        const data = await response.json();
        alert(data.message);
        if (data.result) {
            setBringoutProducts([]);
        }
    }

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>一時出庫</h2>
            <Link to="/TemporarilyTable"><Button variant="contained" size="large" sx={{ position: "absolute", top: 25, right: 50, fontSize: 16, "@media screen and (max-width:959px)": { top: 70, right: 10 } }} >一時出庫テーブル</Button></Link>
            <p className={styles.p}>一時的に在庫から出庫するものを選択してください。</p>
            <div className={styles.scroll}>
                {(bringoutProducts.length > 0) ? <table className={styles.table}>
                    <thead>
                        <tr><th className={styles.th1}>メーカー</th>
                            <th className={styles.th1}>製品名</th>
                            <th className={styles.th3}>色</th>
                            <th className={styles.th2}>左右</th>
                            <th className={styles.th2}>サイズ</th>
                            <th className={styles.th2}>個数</th></tr>
                    </thead>
                    <tbody>
                        {bringoutProducts.map((item, index) => (<tr key={index} className={styles.tr}>
                            <td className={styles.td}>{item.manufacture}</td>
                            <td className={styles.td}>{item.productname}</td>
                            <td className={styles.td}>{item.color}</td>
                            <td className={styles.td}>{item.left_right}</td>
                            <td className={styles.td}>{item.size}</td>
                            <td className={styles.td}>{item.quantity}</td>
                            <td ><Button variant="contained" key={index} onClick={() => handleBack(index)} disabled={(item.quantity < 1)} >戻す</Button></td>
                        </tr>))}
                    </tbody>
                </table> : null}
            </div>
            <Button variant="contained" onClick={handleBringout} sx={{ margin: 2, fontSize: 18, "@media screen and (max-width:959px)": { fontSize: 12 } }}>一時出庫</Button>

            <div className={styles.selectwrapper}>
                <label htmlFor="支店" className={styles.label}>支店
                    <select value={selectedOffice} id="支店" onChange={handleOffice} className={styles.select}>
                        <option value="函館店">函館店</option>
                        <option value="札幌店">札幌店</option>
                        <option value="釧路店">釧路店</option>
                        <option value="室蘭店">室蘭店</option>
                    </select>
                </label>
                <label htmlFor="メーカー" className={styles.label}>メーカー
                    <select value={selectedManufacture} id="メーカー" onChange={handleManufacture} className={styles.select}>
                        <option value="">未設定</option>
                        {selectManufacture.map(item => (<option key={item} value={item}>{item}</option>))}
                    </select>
                </label>
                <label htmlFor="装着部位" className={styles.label}>装着部位
                    <select value={selectedMoldingSite} id="装着部位" onChange={handleMoldingSite} className={styles.select}>
                        <option value="">未設定</option>
                        <option value="A%">下肢装具</option>
                        <option value="D%">上肢装具</option>
                        <option value="C%">体幹装具</option>
                    </select>
                </label>
                <label htmlFor="製品" className={styles.label}>製品
                    <select value={selectedProduct} id="製品" onChange={handleProduct} className={styles.select}>
                        <option value="">未設定</option>
                        {selectProduct.map(item => (<option key={item} value={item}>{item}</option>))}
                    </select>
                </label>
            </div>
            <div className={styles.scroll}>
                <table className={styles.table}>
                    <thead>
                        <tr><th className={styles.th1}>メーカー</th>
                            <th className={styles.th1}>製品名</th>
                            <th className={styles.th3}>色</th>
                            <th className={styles.th2}>左右</th>
                            <th className={styles.th2}>サイズ</th>
                            <th className={styles.th2}>在庫数</th></tr>
                    </thead>
                    <tbody>
                        {products.map((item, index) => (<tr key={index} className={styles.tr}>
                            <td className={styles.td}>{item.manufacture}</td>
                            <td className={styles.td}>{item.productname}</td>
                            <td className={styles.td}>{item.color}</td>
                            <td className={styles.td}>{item.left_right}</td>
                            <td className={styles.td}>{item.size}</td>
                            <td className={styles.td}>{item.quantity}</td>
                            <td ><Button variant="contained" key={index} onClick={() => handleBringoutProduct(index)} disabled={(item.quantity < 1)} >選択</Button></td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}